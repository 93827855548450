.App {
  padding: 0;
  margin: 0;
  position: relative;
  margin-top: 100px;
  height: auto;
  width: 100vw;
  display: flex;
  font-size: 20px;
  font-family: astoria-sans, sans-serif;
  font-style: normal;
  justify-content: center;
  align-items: center;
  background: #fefefe;
}

.toggleButton {
  cursor: pointer;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  background: #383838;
  color: white;
  font-size: 10px;
  border: none;
  outline: none;
  border-radius: 5px;
}

.follow-buttons {
  position: absolute;
  left: 20px;
  font-size: 14px;
  bottom: 20px;
}

a,
a:visited,
a:focus {
  color: #383838;
  font-weight: 400;
  text-decoration: none;
}

.subtitle {
  font-size: 12px;
}

.calculator {
  position: relative;
  background: url(./assets/cover3.png);
  background-size: contain;
  height: 700px;
  width: 500px;
}

.back {
  position: relative;
  background: url(./assets/back.png);
  background-size: contain;
  min-height: 700px;
  min-width: 500px;
}

.output-window {
  position: absolute;
  height: 150px;
  top: 37px;
  left: 56px;
  opacity: 1;
  border-radius: 13px 13px 13px 13px;
  width: 390px;
  background: #b1b4b5;
  font-family: mono45-headline, monospace;
  font-weight: 300;
  color: #383838;
  font-style: normal;
}

.output-window-inner {
  position: relative;
}

.output-window-inner p {
  font-size: 75px;
  margin-top: 30px;
  padding-right: 50px;
  text-align: right;
}

.calculator button {
  position: absolute;
  outline: none;
  border: none;
  min-height: 70px;
  min-width: 70px;
  background-size: cover;
  transform: translateY(-0.5px);
}

.calculator button:hover {
  transform: translateY(0.2px);
}

.zero {
  background: url(./assets/0.png);
  top: 493px;
  left: 165px;
}

.one {
  background: url(./assets/1.png);
  top: 402px;
  left: 64px;
}

.two {
  background: url(./assets/2.png);
  top: 402px;
  left: 165px;
}

.three {
  background: url(./assets/3.png);
  top: 402px;
  left: 267px;
}

.four {
  background: url(./assets/4.png);
  top: 310px;
  left: 64px;
}

.five {
  background: url(./assets/5.png);
  top: 310px;
  left: 165px;
}

.six {
  background: url(./assets/6.png);
  top: 310px;
  left: 267px;
}

.seven {
  background: url(./assets/7.png);
  top: 219px;
  left: 64px;
}

.eight {
  background: url(./assets/8.png);
  top: 219px;
  left: 165px;
}

.nine {
  background: url(./assets/9.png);
  top: 219px;
  left: 267px;
}

.plus {
  background: url(./assets/plus.png);
  top: 493px;
  left: 368px;
}

.minus {
  background: url(./assets/minus.png);
  top: 402px;
  left: 368px;
}

.multiply {
  background: url(./assets/multiply.png);
  top: 310px;
  left: 368px;
}

.divide {
  background: url(./assets/Divide.png);
  top: 219px;
  left: 368px;
}

.dot {
  background: url(./assets/dot.png);
  top: 493px;
  left: 64px;
}

.clear {
  background: url(./assets/C.png);
  top: 493px;
  left: 267px;
}

.equal {
  background: url(./assets/equal.png);
  top: 584px;
  left: 368px;
}

@media (max-width: 768px) {
  .App {
    margin-top: 0px;
    touch-action: manipulation;
    transform: scale(0.8);
    height: 100vh;
  }
  .zero {
    background: url(./assets/0.png);
    top: 493px;
    left: 163px;
  }

  .one {
    background: url(./assets/1.png);
    top: 402px;
    left: 62px;
  }

  .two {
    background: url(./assets/2.png);
    top: 402px;
    left: 163px;
  }

  .three {
    background: url(./assets/3.png);
    top: 400px;
    left: 265px;
  }

  .four {
    background: url(./assets/4.png);
    top: 310px;
    left: 62px;
  }

  .five {
    background: url(./assets/5.png);
    top: 310px;
    left: 163px;
  }

  .six {
    background: url(./assets/6.png);
    top: 310px;
    left: 265px;
  }

  .seven {
    background: url(./assets/7.png);
    top: 219px;
    left: 62px;
  }

  .eight {
    background: url(./assets/8.png);
    top: 219px;
    left: 163px;
  }

  .nine {
    background: url(./assets/9.png);
    top: 219px;
    left: 265px;
  }

  .plus {
    background: url(./assets/plus.png);
    top: 493px;
    left: 366px;
  }

  .minus {
    background: url(./assets/minus.png);
    top: 402px;
    left: 366px;
  }

  .multiply {
    background: url(./assets/multiply.png);
    top: 310px;
    left: 366px;
  }

  .divide {
    background: url(./assets/Divide.png);
    top: 219px;
    left: 366px;
  }

  .dot {
    background: url(./assets/dot.png);
    top: 493px;
    left: 62px;
  }

  .clear {
    background: url(./assets/C.png);
    top: 491px;
    left: 265px;
  }

  .equal {
    background: url(./assets/equal.png);
    top: 584px;
    left: 366px;
  }
}
